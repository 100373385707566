.navbar {
  background: transparent;
  .navbar-burger {
    width: 5.25rem;
    height: 5.25rem;
    background: transparent;
    border: none;
    span {
      height: 2px;
      width: 26px;
      left: calc(50% - 10px);
      color: $white;
      &:nth-child(1) {
        top: calc(50% - 12px);
      }
      &:nth-child(2) {
        top: calc(50% - 4px);
      }
    }
  }
  .navbar-item {
    background: black;
    color: $white;
    text-transform: uppercase;
    span {
      padding: 0.5rem 1.2rem;
    }
    &:hover, &:focus {
      color: darken($white, 20%);
      background: darken($primary-color, 20%);
      @media ( min-width: 1024px ) {
        background: $black;
      }
      span {
        background: darken($primary-color, 20%);
        @media ( min-width: 1024px ) {
          background: darken($primary-color, 20%);
          border-radius: 1rem;
        }
      }
    }
    img {
      max-height: none;
    }
    &.logo {
      background: $black;
    }
    @media ( max-width: 1023px ) {
      background: darken($primary-color, 20%);
    }
  }
  .navbar-menu {
    position: absolute;
    display: none;
    width: 100%;
    background: $black;
    padding: 2rem 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    text-align: center;
    font-size: 1.5rem;
    @media ( min-width: 1024px ) {
      display: block;
      position: relative;
      width: auto;
      height: auto;
      background: transparent;
      text-align: left;
      font-size: 1rem;
      .navbar-start {
        justify-content: flex-end;
      }
      .navbar-item {
        padding: 0;
        &.logo {
          padding: 0rem 0.75rem 0.5rem;
          background: $black;
        }
      }
    }
  }
  .privacy, .partners {
    display: none;
  }
}
@media ( min-width: 1024px ) {
  .modal {
    display: none!important;
  }
}

@media ( max-width: 1023px ) {
  .modal-background {
    background: darken($primary-color, 20%);
  }
  .modal-content {
    background: darken($primary-color, 20%);
    text-align: center;
    font-size: 1.2rem;
  }
}
