.about-blurb {
  margin-bottom: 2rem;
}

.timeline-line {
    position: absolute;
    left: 50%;
    margin-left: 0;
    height: 100%;
    border-right: 1px solid #d0d0d0;
    z-index: -1;
}

.timeline {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  display: inline-block;
  .year-tag {
    position: relative;
    display: block;
    width: 100px;
    margin: 0.5rem auto;
    padding: 0.2rem 0.5rem;
    text-align: center;
    background: darken($primary-color, 20%);
    border-radius: 0.5rem;
    color: $white;
    clear: both;

  }
  .milestone {
    position: relative;
    display: inline-grid;
    align-items: center;
    border: 1px solid $black;
    background: #fcfcfc;
    border-radius: 0.5rem;
    width: 48%;
    clear: both;
    padding: 1rem;
    margin: 2rem 0;
    @media (max-width: 1023px) {
      width: 80%;
    }
    .milestone-title {
      @media (max-width: 1023px) {
        font-size: 1rem;
        text-align: center;
      }
    }
    .milestone-icon {
      position: absolute;
      height: 60px;
      width: 60px;
      background: $primary-color;
      border-radius: 30px;
    }
    .milestone-dot {
      position: absolute;
      height: 10px;
      width: 10px;
      background: black;
      border-radius: 5px;
      @media (max-width: 1023px) {
        display: none;
      }
    }
    &.left {
      float: left;
      @media (max-width: 1023px) {
        float: none;
      }
      .milestone-icon {
        left: -1.5rem;
      }
      .milestone-title, .milestone-subtitle {
        text-align: right;
      }
      .milestone-dot {
        right: -5%;
      }
      @media (min-width: 1024px) {
        &:after, &:before {
          left: 100%;
        	top: 50%;
        	border: solid transparent;
        	content: "";
        	height: 0;
        	width: 0;
        	position: absolute;
        	pointer-events: none;
        }
        &:after {
          border-color: rgba(255, 255, 255, 0);
        	border-left-color: #ffffff;
        	border-width: 10px;
        	margin-top: -10px;
        }
        &:before {
          border-color: rgba(0, 0, 0, 0);
        	border-left-color: #000000;
        	border-width: 11px;
        	margin-top: -11px;
        }
      }
    }
    &.right{
      float: right;
      @media (max-width: 1023px) {
        float: none;
      }
      .milestone-icon {
        right: -1.5rem;
      }
      .milestone-title, .milestone-subtitle {
        text-align: left;
      }
      .milestone-dot {
        left: -5%;
      }
      @media (min-width: 1024px) {
        &:after, &:before {
          right: 100%;
        	top: 50%;
        	border: solid transparent;
        	content: "";
        	height: 0;
        	width: 0;
        	position: absolute;
        	pointer-events: none;
        }
        &:after {
          border-color: rgba(255, 255, 255, 0);
        	border-right-color: #ffffff;
        	border-width: 10px;
        	margin-top: -10px;
        }
        &:before {
          border-color: rgba(0, 0, 0, 0);
        	border-right-color: #000000;
        	border-width: 11px;
        	margin-top: -11px;
        }
      }
    }
    h4, p {
       margin-bottom: 0;
    }
  }
}
