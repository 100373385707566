.studio-blurb {
  position: relative;
  margin: 0 auto;
  padding: 1rem 0 3rem;
  text-align: center;
  font-size: 22px;
  font-weight: 300;
  width: 80%;
  line-height: 40px;
  @media ( min-width: 1024px ) {
    padding: 3rem 0 5rem;
    font-size: 28px;
    width: 60%;
  }
}

.video-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 400px;
  video {
    height: 400px;
    width: 100%;
    object-fit: cover;
  }
}

.mission-blurb {
  position: absolute;
  text-align: center;
  padding: 5rem 0;
  top: 1rem;
  margin: 0 auto;
  width: 100%;
  left: 0;
  span {
    color: $white;
    font-size: 25px;
    font-weight: 500;
    text-transform: uppercase;
    @media ( min-width: 1024px ) {
      font-size: 28px;
    }
  }
  h2 {
    color: $white;
    font-size: 50px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    margin-top: 0rem!important;
    @media ( min-width: 1024px ) {
      font-size: 85px;
    }
  }
  p {
    width: 80%;
    color: $white;
    font-size: 20px;
    margin: 0 auto;
    @media ( min-width: 1024px ) {
      font-size: 25px;
      width: 100%;
    }
  }
}

.squiggle {
  background: url("../assets/images/squiggle.png");
  width: 70px;
  height: 10px;
  background-position: center;
  background-size: cover;
  display: inline-block;
}

.partners-section {
  padding: 2rem 1rem;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  @media ( min-width: 1024px ) {
    width: 80%;
    padding: 3rem;
  }
  .partner-blurb {
    display: block;
    font-size: 18px;
    margin-bottom: 2rem;
  }
}

.partner-button-holder {
  display: flex;
  justify-content: center;
  margin: 1.5rem 0;
}

.latest-news-section {
  position: relative;
  text-align: center;
  background: url('https://res.cloudinary.com/deregallera/image/upload/v1615639559/panel-dots.jpg');
  background-color: $light-grey;
  background-size: cover;
  width: 100%;
  height: auto;
  padding: 2rem 1rem;
  border-top: 3px solid $light-grey;
  border-bottom: 3px solid $light-grey;
  @media ( min-width: 1024px ) {
    padding: 3rem 0;
  }
  .squiggle {
    margin-bottom: 1rem;
  }
  .columns {
    @media ( min-width: 1024px ) {
      width: 80%;
      margin: 0 auto;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($white, 0.9);
    z-index: 0;
  }
  span {
    position: relative;
    z-index: 1;
    color: $white;
    font-size: 25px;
    font-weight: 500;
    text-transform: uppercase;
    @media ( min-width: 1024px ) {
      font-size: 28px;
    }
  }
  h2 {
    position: relative;
    z-index: 1;
    color: $white;
    font-size: 50px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    margin-top: 0rem!important;
    @media ( min-width: 1024px ) {
      font-size: 55px;
    }
  }
  .news-thumb {
    padding: 0;
    .news-image {
      height: 220px;
      width: 100%;
      object-fit: cover;
    }
  }
  .overlay-container {
    position: relative;
    height: 99%;
    line-height: 1;
    .overlay {
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      background-color: rgba($black, 0.7);
      overflow: hidden;
      width: 100%;
      height:0;
      transition: .5s ease;
      @media ( max-width: 768px ) {
        bottom: 0;
        height: 100%;
      }
    }
    &:hover {
      .overlay {
        bottom: 0;
        height: 100%;
      }
    }
    .text {
      color: white;
      padding: 1rem;
      font-size: 18px;
      position: relative;
      text-align: left;
      line-height: 1.2;
    }
    .button {
      position: absolute;
      right: 1rem;
      bottom: 0;
      padding: 0.5rem 0.8rem;
      font-size: 0.875rem;
    }
  }
}

.bottom-text {
  text-align: center;
  padding: 2rem 0;
  width: 90%;
  margin: 0 auto;
  word-wrap: break-word;
  white-space: break-spaces;
  font-size: 1.2rem;
  @media ( min-width: 1024px ) {
    width: 60%;
    padding: 5rem 0;
  }
}

.share-buttons {
  margin-top: 1.5rem;
}
